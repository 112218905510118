import { Grid } from '@mui/material';
import '../style/card3d.css'
import Logo from '../images/pc.png'
import Logo2 from '../images/fibra.jpg'

function card3d() {

    function revealcard() {
        var reveals = document.querySelectorAll(".card");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("activecard");
            } else {
                reveals[i].classList.remove("activecard");
            }
        }
    }
    window.addEventListener("scroll", revealcard);
    revealcard();


    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={12}>
                <div className='presentation'>
                    <div href="" alt="Mythrill" target="_blank">
                        <div className="card">
                            <div className="wrapper">
                                <img src={Logo2} alt='assistenza web' className="cover-image" />
                            </div>
                            <div className="title styletext">Assistenza<br></br>annuale</div>
                            <img src={Logo} style={{width:'150%'}} className="character" alt='assistenza web' />
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default card3d;