import * as React from 'react';
import '../style/footer.css'
import Fiver from '../images/fiverlogo.png'
import Linkedin from '../images/linkedinlogo.png'
import CV from '../images/cvlogo.png'


export default function Footer() {
  return (
    <body>
      <footer class="footer">
        <ul class="social-icon">
          <li className="social-icon__item"><a className="social-icon__link"
                                               href="https://it.fiverr.com/cristoferrusso?up_rollout=true">
            <img src={Fiver} style={{width: '50px'}}></img>
          </a></li>
          <li className="social-icon__item"><a className="social-icon__link"
                                               href="https://www.linkedin.com/in/cristofer-russo-541327201/">
            <img src={Linkedin} style={{width: '50px'}}></img>
          </a></li>
          <li className="social-icon__item">
            <a
                className="social-icon__link"
                href="/assets/CV%20Russo%20Cristofer.pdf"
                download="CV_Cristofer_Russo.pdf"
            >
              <img src={CV} style={{width: '50px'}} alt="LinkedIn"/>
            </a>
          </li>
        </ul>
        <ul class="menu">
          <li>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
                 style={{color: 'white', textAlign: 'left'}}>
              <h6 className="text-uppercase fw-bold mb-4">CRISTOFER RUSSO | WEB DEVELOPER</h6>
              <p>
              Via Santa Cecilia  – 24011 Almè (Bg)<br></br>
              Cell. +39 3421437909 <br></br>
              cristofer.russo.dev@gmail.com
            </p>
            <a style={{ textDecoration: 'none', color: 'white' }} href="#" onclick="window.displayPreferenceModal();return false;" id="termly-consent-preferences">Consent Preferences</a>
          </div>
          </li>
        </ul>
      </footer>
    </body>
  );
}